import React, { useState } from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import Modal from "./Modal"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import FileInput from "./inputs/FileInput"
import FormButtonsContainer from "./buttons/ButtonsContainer"
import SubmitButton from "./buttons/SubmitButton"
import ExploreButton from "./buttons/ExploreButton"
import BasicInput from "./inputs/BasicInput"
import TextAreaInput from "./inputs/TextAreaInput"
import StyledForm from "./StyledForm"
import PrivacyLabel, { StyledLink } from "./PrivacyLabel"
import InputRow from "./InputRow"
import SelectInput from "./inputs/SelectInput"
import { useRecruitee } from "../../../hooks/useRecruitee"
import { useStaticQuery, graphql } from "gatsby"
import { submitApplication } from "./SubmitApplication"

const POSITION_ID_QUERY = graphql`
  query GetPositionData {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/src/data/open-positions/" } }
    ) {
      nodes {
        frontmatter {
          mainTitle
          recruitee_id
        }
      }
    }
  }
`

const ApplySchema = Yup.object().shape({
  name: Yup.string().max(50, "Name is too long").required("Name is required"),
  email: Yup.string()
    .email("Enter correct email")
    .required("Email is required"),
  links: Yup.string().max(300, "Max 300 charecters"),
  message: Yup.string().max(4000, "Message is too long"),
})

const ApplyForm = ({
  exploreButton = true,
  positionField = true,
  recruiteeId = "software-developers",
}) => {
  const [showModal, setShowModal] = useState(false)
  const [emailError, setEmailError] = useState()
  const [fileError, setFileError] = useState()
  const [selectedFiles, setSelectedFiles] = useState([])
  const { pathname } = useLocation()
  const [keepValues, setKeepValues] = useState(false)

  const positionData = useStaticQuery(POSITION_ID_QUERY)
  const [setRecruiteeId, recruiteeData] = useRecruitee(recruiteeId)

  const toggleModal = () => {
    // if on open-positions closing modal returns to previous page i.e the article
    if (pathname.includes("/open-positions/") && showModal && !emailError) {
      navigate(`/open-positions/${pathname.split("/")[2]}`)
    }
    setShowModal(!showModal)
  }

  return (
    <>
      <Formik
        initialValues={{
          name: "",
          email: "",
          links: "",
          message: "",
          response: "",
        }}
        validationSchema={ApplySchema}
        onSubmit={async (values, { resetForm }) => {
          if (fileError) return
          await submitApplication(
            values,
            setEmailError,
            selectedFiles,
            recruiteeData,
            setKeepValues
          )
          if (keepValues === false) {
            resetForm({ values: "" })
            setSelectedFiles([])
          }
          toggleModal()
        }}
      >
        {({ isSubmitting }) => (
          <StyledForm>
            <InputRow>
              <BasicInput
                label="Name*"
                name="name"
                autoComplete="name"
                type="text"
                placeholder="Enter your name"
              />
              <BasicInput
                label="Email*"
                name="email"
                autoComplete="email"
                type="email"
                placeholder="Enter your email"
              />
            </InputRow>
            <TextAreaInput
              label="Tell us about yourself"
              name="message"
              placeholder="Enter your message"
              rows="3"
            />
            <InputRow>
              <BasicInput
                label="Relevant links"
                name="links"
                type="text"
                placeholder="Enter relevant links"
              />
              {positionField && (
                <SelectInput
                  label="Position"
                  name="position"
                  setPosition={setRecruiteeId}
                  options={[
                    ...positionData.allMarkdownRemark.nodes.map((node, i) => {
                      return {
                        value: {
                          recruiteeId: node.frontmatter.recruitee_id,
                          index: i,
                        },
                        label: node.frontmatter.mainTitle,
                      }
                    }),
                    {
                      value: {
                        recruiteeId: "software-developers",
                        index: "other",
                      },
                      label: "Other",
                    },
                  ]}
                />
              )}
            </InputRow>
            <FileInput
              name="files"
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              fileError={fileError}
              setFileError={setFileError}
              placeholder="Files"
            />
            <PrivacyLabel>
              By sending this application, you confirm that you have read
              Rebase's{" "}
              <StyledLink to="/privacy-notice">privacy notice</StyledLink> and
              give your consent to handling your candidate data.
            </PrivacyLabel>
            <FormButtonsContainer>
              <SubmitButton label="Apply now" isSubmitting={isSubmitting} />
              {exploreButton && <ExploreButton />}
            </FormButtonsContainer>
          </StyledForm>
        )}
      </Formik>
      <Modal isOpen={showModal} error={emailError} closeModal={toggleModal} />
    </>
  )
}

export default ApplyForm
